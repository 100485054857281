/* C-FONTS */
@font-face {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 100;
   src: url('./fonts/Poppins-Thin.ttf');
}

@font-face {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 200;
   src: url('./fonts/Poppins-ExtraLight.ttf');
}

@font-face {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 300;
   src: url('./fonts/Poppins-Light.ttf');
}

@font-face {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   src: url('./fonts/Poppins-Regular.ttf');
}

@font-face {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   src: url('./fonts/Poppins-Medium.ttf');
}


@font-face {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 600;
   src: url('./fonts/Poppins-SemiBold.ttf');
}

@font-face {
   font-family: 'Poppins';
   font-weight: 700;
   font-style: normal;
   src: url('./fonts/Poppins-Bold.ttf');
}

@font-face {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 800;
   src: url('./fonts/Poppins-ExtraBold.ttf');
}


@font-face {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 900;
   src: url('./fonts/Poppins-Black.ttf');
}

/* C-CONTAINER */
.container {
   margin: 0 auto;
   width: 100%;
   padding: 0 15px;
}

@media (min-width: 1024px) {
   .container {
      max-width: 992px;
   }
}

@media (min-width: 1200px) {
   .container {
      max-width: 1150px;
   }
}

@media (min-width: 1300px) {
   .container {
      max-width: 1250px;
   }
}

@media (min-width: 1400px) {
   .container {
      max-width: 1350px;
   }
}

/* C-UNIVERSAL */
* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: Poppins, "sans-serif";
}

#__e-commerce {
   height: 100dvh;
   display: flex;
   flex-direction: column;
}

a {
   text-decoration: none;
   color: unset;
}

input,
button,
textarea {
   border: none;
   outline: none;
   background: none;
   border-radius: 0px;
}

section:not(.no_p) {
   padding: 50px 0 0;
   position: relative;
}

/* C-VARIABLES */
:root {
   --white: #fff;
   --black: #000;
   --primary: #272727;
   --secondary: #ffc95f;
   --rating: orange;
}

/* C-HEADING */
.heading {
   font-size: clamp(20px, 2vw, 30px);
   font-weight: 600;
   text-transform: capitalize;
   display: inline-block;
   padding-right: 20px;
   background: var(--white);
   margin-bottom: 40px;
}

/* C-HEADER */
header {
   background: #161616;
}
.header_main {
    display: flex;
    align-items: center;
}
.header_top_main {
    display: flex;
    grid-gap: 10px;
    align-items: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px 0;
}
.header_top{
   flex: 1;
}
.header_top_main>* {
   flex: 1;
   display: flex;
}

.header_btn_wrapper {
   justify-content: flex-end;
}

.header_btn_wrapper button {
   padding: 10px 20px;
   background: var(--secondary);
   border-radius: 5px;
   font-size: 13px;
   font-weight: 500;
   text-transform: capitalize;
}

/* C-LOGO */
.logo {
   height: 100%;
   width: auto;
   width: 210px;
   display: flex;
   align-items: center;
   padding: 0 10px;
}

.logo img {
   max-width: 100%;
   max-height: 100%;
}

.header_btn {
   margin-left: auto;
}

/*
.header_contact{
   background: var(--primary);
   padding: 10px 0;
}*/
.header_contact_main {
   background: var(--primary);
   padding: 10px 20px;
   overflow-x: auto;
   display: flex;
   align-items: center;
   grid-gap: 10px;
   justify-content: space-between;
   border-radius: 50px;
}

.header_contact_main a {
   color: var(--white);
   font-size: 15px;
   text-decoration: underline;
   font-weight: 500;
   text-transform: capitalize;
   padding-left: 20px;
   border-left: 1px solid gray;

}

.header_address {
   color: var(--white);
   font-size: 13px;
   flex: 0 0 auto;
   align-items: center;
   grid-gap: 10px;
   display: flex;
   justify-content: center;
}

.header_address svg {
   height: 20px;
   width: 20px;
   flex: 0 0 20px;
   stroke-width: 1.5px;
}

.header_contact_flex {
   display: flex;
   align-items: center;
   grid-gap: 10px 20px;
}
.amazon_link {
    padding: 5px 20px 5px 10px !important;
    background: #ff9700;
    border-radius: 100px;
    display: flex;
    color: #fff !important;
    font-weight: 400 !important;
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
    border: none !important;
    text-transform: unset !important;
    grid-gap: 5px;
}
.amazon_link img {
    height: 40px;
    width: 40px;
    object-fit: contain;
    filter: brightness(1000) contrast(100%);
}
.amazon_content{
   display: flex;
   flex-direction: column;
   grid-gap: 4px;
}
.amazon_content p{
   font-size: 15px;
   font-weight: 500;
   line-height: 1;
}
.amazon_content span{
   font-size: 12px;
   line-height: 1;
}
.menu_btn {
   height: 40px;
   flex: 0 0 40px;
   width: 40px;
   flex: 0 0 40px;
   display: flex;
   align-items: center;
   justify-content: center;
    transition: 0s ease 0.5s;
   padding: 5px;
   border-radius: 5px;
   display: none;
}
.menu_btn svg {
    height: 100%;
    width: 30px;
    stroke: var(--white);
}

/* C-NAVIGATION */
nav {
   align-self: stretch;
   justify-content: flex-end;
}

.navigation {
   display: flex;
   grid-gap: 30px;
   justify-content: center;
   list-style: none;
   height: 100%;
}

.navigation a {
   display: flex;
   align-items: center;
   height: 100%;
   font-size: 14px;
   font-weight: 500;
   padding: 10px 0;
   text-transform: capitalize;
   color: #FFF;
   border-bottom: 4px solid transparent;
   transition: 0.2s ease;
}

.navigation a:where(.active, :hover) {
   border-color: var(--secondary);
}

/* button */
.btn {
   padding: 10px 20px;
   background: var(--secondary);
   border-radius: 5px;
   cursor: pointer;
}

.btn span {
   font-size: clamp(14px, 2vw, 15px);
   text-transform: capitalize;
   font-weight: 500;
}

/* C-DEAL */
.slider_parent {
   position: relative;
}

.top_deals_banner {
   padding-bottom: 0 !important;
}

.deal_slide_btn.swiper-button-next {
   right: -20px;
}

.deal_slide_btn.swiper-button-prev {
   left: -20px;
}

.deals_banner {
   border-radius: 10px;
   overflow: hidden;
}

.deal_slide {
   height: 350px;
   position: relative;
}

.deal_slide figure {
   overflow: hidden;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.deal_slide figure img {
   height: 100%;
   width: 100%;
   object-fit: cover;
}

.deal_slide_content_main {
   position: absolute;
   padding: 40px 70px;
   width: 100%;
   bottom: 0;
   left: 0;
   background: linear-gradient(0deg, #000000c2 20%, transparent 100%);
}

.deal_title {
   font-size: clamp(30px, 2vw, 40px);
   font-weight: 600;
   color: var(--white);

    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.deal_description {
   font-size: clamp(14px, 2vw, 16px);
   color: var(--white);    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
   font-weight: 300;
}

.deal_slide_content {
   max-width: 800px;
   display: flex;
   flex-direction: column;
   opacity: 0;
   transition-delay: 1s;
   grid-gap: 15px;
   align-items: flex-start;
}

.deal_btn {
   margin-top: 10px;
}

.swiper-slide-active .deal_slide_content {
   opacity: 1;
   animation: slideIn 0.8s ease-in-out 1s;
}

@keyframes slideIn {
   0% {
      opacity: 0;
      transform: translateX(-50px);
   }

   100% {
      opacity: 1;
      transform: translateX(0);
   }
}

.deal_slide_btn {
   height: 40px;
   width: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
   background: var(--secondary);
   padding: 5px;
   border-radius: 5px;
   top: 50%;
   transform: translateY(-50%);
}

.deal_slide_btn:after {
   font-size: 20px;
   color: var(--black);
   font-weight: 600;
}

/* C-TOP CATEGORIES */
.t_cat {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
}

.t_cat_item {
    padding: 30px;
    background: #ececec;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #161616;
    min-height: 100px;
}

.cat_title {
   font-size: clamp(20px, 2vw, 22px);
   font-weight: 600;
   color: var(--primary);
   text-transform: capitalize;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-box-orient: vertical;
}

/* C-FOOTER */
.footer-container {
    background-color: #050505;
    padding: 40px 0 30px 0;
    margin-top: 50px;
}
.footer_main {
   grid-gap: 30px;
   display: flex;
   flex-wrap: wrap;
}
.footer_main .logo{
   align-self: center;
   margin: auto;
}
.footer_main>div {
    flex: 1 1 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-container h4 {
   margin-bottom: 20px;
   font-size: clamp(18px, 2vw, 20px);
   font-weight: 600;
   color: var(--white);
}

.footer-container ul {
   list-style-type: none;
   padding: 0;
   margin: 0;
   text-align: center;
}

.footer-container ul li {
   margin-bottom: 10px;
}

.footer-container a {
   color: lightgray;
   text-decoration: none;
   font-size: 15px;
}

.footer-container a:hover {
   text-decoration: underline;
}

.footer-column {
   padding-top: 30px;
   border-top: 1px solid #004b5e;
}

.footer-text {
   font-size: 14px;
   color: #888;
   text-align: center;
   color: #fff;
}

.social_icons {
   display: flex;
   align-items: center;
   flex-direction: row !important;
   grid-gap: 10px;
   justify-content: flex-start;
}
.icon {
   height: 40px;
   width: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 6px;
   cursor: pointer;
   transition: 0.3s ease;
   overflow: hidden;
}

.icon figure {
   height: 20px;
   width: 20px;
}

.google_plus figure {
    height: 25px;
    width: 25px;
}

.icon figure img {
   object-fit: contain;
}

.icon.facebook {
   background: #1877f2;
}

.icon.twitter {
   background: #5da9dd;
}

.icon.google_plus {
   background: #e13838;
}

.icon.linkedin {
   background: #0250b1;
}

.icon.pinterest {
   background: #e60023;
}

.icon.instagram {
   background: linear-gradient(216deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
}
.icon figure {
       display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    height: 20px;
    width: 20px;
}
.icon figure img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}
.google_plus figure {
    height: 25px;
    width: 25px;
}
/* C-PRODUCT-CARD */
.product-card {
   background-color: #fff;
   border-right: 2px solid #16161621;
   padding: 0 30px;
   text-align: center;
   height: 100%;
   display: flex;
   grid-gap: 10px;
   flex-direction: column;
   align-items: center;
}

.swiper-slide:has(.product-card) {
   height: unset;
   max-width: 330px;
}
.product_tag{
   padding: 5px 15px;
   display: inline-block;
   background: #161616;
   color: var(--white);
   border-radius: 5px;
   position: absolute;
   line-height: 1;
   font-size: 14px;
   font-weight: 500;
   text-transform: capitalize;
   top: 10px;
   left: 10px;
}
.product-image {
   width: 100%;
   height: 200px;
   position: relative;
   overflow: hidden;
   border-radius: 8px;
}

.product-image img {
   height: 100%;
   width: 100%;
   object-fit: contain;
}

.product-title {
   font-size: 18px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
   font-weight: 600;
}

.product-description {
    font-size: 14px;
    color: #888;
    margin-bottom: auto;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.product-price {
   font-size: 20px;
   color: #ff5757;
   font-weight: 700;
}

.product-button {
   background-color: #ffc95f;
   color: var(--black);
   border: none;
   border-radius: 4px;
   padding: 10px 16px;
   font-weight: 500;
   text-transform: capitalize;
   font-size: 14px;
   cursor: pointer;
   transition: 0.2s ease;
   display: flex;
   align-items: center;
   grid-gap: 5px;
}

.product-button:hover {
   background-color: #ffbc00;
}

.product-button svg {
   height: 20px;
   width: 20px;
   stroke-width: 1.5px;
}

.product_card_footer {
   display: flex;
   align-items: center;
   justify-content: space-between;
   grid-gap: 10px;
   width: 100%;
   margin-top: auto;
   padding-top: 20px;
   margin-top: 10px;
   border-top: 2px solid #16161621;
}

/* C-CONTACT */
.contact_main {
   display: flex;
   grid-gap: 30px;
}

.contact_sidebar {
   flex: 0 0 500px;
}

.contact_wrapper {
   flex: 1;
}
.contact_wrapper .heading{
   margin-bottom: 20px;
}
.input_field {
   display: flex;
   flex-direction: column;
   grid-gap: 5px;
}

.input_field :where(input,textarea) {
   height: 100%;
   width: 100%;
   font-size: 15px;
   padding: 12px 10px;
   border-radius: 5px;
   border: 1px solid #0003;
}
.input_field label {
   font-size: 15px;
   font-weight: 600;
   text-transform: capitalize;
   color: var(--primary);
}

.contact_form {
   display: flex;
   flex-wrap: wrap;
   grid-gap: 20px;
   align-items: center;
}

.contact_form>.input_field {
   flex: 1 1 350px;
}

.input_field:has(textarea){
   flex: 0 0 100% !important;
}
.submit_btn {
   flex: 0 0 100%;
}

.mapouter {
   position: relative;
   text-align: right;
}

.gmap_canvas {
   overflow: hidden;
   background: none !important;
}
/* C-ABOUT */
.about p {
  margin-bottom: 20px;
  line-height: 1.6;
  color: #333;
}
.sub_heading {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* C-ALL-PRODUCT */
.all_product_grid{
   display: grid;
   grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
   grid-gap: 30px;
}
.all_product_grid .product-card {
    padding: 20px;
    border: none;
    background: #f8f8f8;
    border-radius: 10px;
    border: 2px solid #16161621;
}
.all_product_grid .product-card .product-image{
    border: 2px solid #16161621;
}